.copyrightFooterContainer {
  background: #333333;
}

.copyrightFooter {
  padding: 8px 35px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
  position: relative;
}

.socialIcons {
  & > span {
    margin-right: 10px;
  }
  & > li {
    display: inline-block;
  }
  svg {
    vertical-align: middle;
    width: 28px;
    height: 28px;
    margin: 0 10px;
    &:last-child {
      border-radius: 16px;
      background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
        radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
        radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
        radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
        radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
        linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
    }
  }
}

.paymentGateway {
  & svg {
    vertical-align: middle;
    margin-left: 5px;
    width: 35px;
    height: 35px;
  }
}
