@import "/styles/themes/colors";

.limitedCatalog {
  padding: 8px;
  text-align: center;
  color: $red;
  font-size: 14px;
  text-align: left;
  display: block;
  font-weight: 500;
  margin: 10px 0;
  border-radius: 8px;
  line-height: 1.5;
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  span {
    margin-right: 2px;
  }
}
.imageSection{
    width: 44px;
    height: 44px;
    background: $white;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
.contentSection{
    width: calc(100% - 68px);
}
.limitedCatalogCalender {
  font-size: 16px;
  margin: 10px 20px;
}
.limitedCatalogMobile {
  font-size: 16px;
  text-align: left;
}
.limitedCatalogMobile.limitedCatalogCalender{
  margin: 10px;
}
