@import "../../../../styles/themes/colors";

.footer {
  width: 100%;
  overflow: hidden;
  background: #f2f2f2;
}

.socialLinks {
  float: left;
  width: 100%;
  text-align: center;
  padding: 0 1%;
  box-sizing: border-box;
  margin-bottom: 30px;
  margin-top: 10px;
  line-height: 2.5;
}

.socialLinksUL {
  justify-content: center;
}
.socialLinksLI {
  display: inline-block;
  width: 18% !important;
  list-style: none;
  font-size: 36px;
  box-sizing: border-box;
  height: auto !important;
  padding-bottom: 20px;
  margin: 0 3px 10px 3px;
  margin-top: -8px;
  margin-bottom: 35px;
}

.socialLinksAnch {
  width: 57px;
  height: 55px;
  border-radius: 50%;
  display: inline-block;
  line-height: 55px;
  text-align: center;
  font-size: 24px;
  background: url(/assets/images/sprit-bg-bb.png) no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-position-x: 0%;
  background-position-y: 0%;
}
.FB {
  background-position: -60px 17px;
  background-color: #fff;
}
.TW {
  background-position: -169px 17px;
  background-color: #fff;
}
.PIN {
  background-position: -224px 17px;
  background-color: #fff;
}
.INST {
  background-position: -281px 17px;
  background-color: #fff;
}
.row {
  max-width: 100%;
  margin-right: auto !important;
  margin-left: auto !important;
}
.footerLinks {
  float: left;
  width: 100%;
  text-align: center;
}
.footerLi {
  float: left;
  width: 100%;
  padding: 10px;
  margin: 20px 0 !important;
  box-sizing: border-box;
  list-style: none;
  line-height: normal;
  height: auto !important;
}
.footerLinksA {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: $darkBlack;
  text-decoration: none;
}
.safeMsg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-weight: 400;
  float: left;
  border-radius: 4px;
  white-space: nowrap;
  padding-top: 12px;

  svg {
    color: $green;
  }
}
.miniAppSafeMsg {
  margin-bottom: 110px;
}
.safe-secure {
  margin-left: 4px;
  color: $lightGrey;
}
.socialSpan {
  font-size: 13px;
  color: $lightGrey;
  font-weight: 400;
  margin-bottom: 15px;
}
