@import "/styles/themes/colors";

.autosuggest-wrapper {
  position: relative;
  #react-autowhatever-1 {
    li {
      padding: 0;
      position: relative;
    }
  }
  #react-autowhatever-1.react-autosuggest__suggestions-container--open {
    border: none;
  }

  .place-pin {
    top: 42px;
  }

  .invalid-area,
  .city {
    padding: 11px 11px 11px 30px;
    font-size: 14px;
    border: 1px solid #e6e6e6;
    color: $darkBlack;
  }

  :global {
    .react-autosuggest__container {
      border: 0;
      .m-site-autosuggest-input {
        min-height: 44px;
        margin-bottom: 0px;
      }
      input {
        display: block;
        padding: 8px 13% 8px 30px;
        border-radius: 4px;
        line-height: 26px;
        font-size: 20px;
        margin: 0;
        transition: all 0.25s ease-in-out;
        height: auto;
        width: 100%;
        font-weight: 400;
        margin-bottom: 3px;
        border: 1px solid $lightGrey;
        color: $darkBlack;
        background: $white;
        min-height: 52px;
        line-height: 26px;
        padding-left: 35px;
        outline: none;
      }
      fieldset {
        display: none;
      }
      outline: none;
    }

    .react-autosuggest__suggestions-container--open {
      overflow-y: auto;
      z-index: 99;
      top: 44px;
      position: absolute;
      max-height: 355px;
      width: 100%;
      background-color: $bgGrey;
      border: none;
      transition: max-height 0.8s ease-in-out;
      -webkit-transition: max-height 0.8s ease-in-out;
      -moz-transition: max-height 0.8s ease-in-out;
      -o-transition: max-height 0.8s ease-in-out;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
    }
    .react-autosuggest__suggestion--highlighted {
      background-color: #ebf2fe;
    }
    .react-autosuggest__suggestion-match {
      color: $red;
      font-weight: 600;
    }

    .google-delivery-area-wrapper .invalid-area {
      text-align: left;
      color: $red;
    }
    .delivery-area-form-wrapper .close-icon {
      top: 35px;
      right: 10px;
    }
    .delivery-area-form-wrapper .notDeliverable {
      border: 1px solid $red !important;
      box-shadow: 0 0 8px $red !important;
      color: $red;
      font-weight: 500;
    }
    #destlookupintl,
    #destlookup {
      margin-top: 10px;
      margin-bottom: 10px;
      height: 44px;
      padding: 8px 13% 8px 30px !important;
    }

    .drop-down-suggestions-list {
      overflow-y: auto;
      z-index: 99;
      top: 44px;
      position: absolute;
      max-height: 355px;
      width: 100%;
      background-color: $bgGrey;
      border: none;
      transition: max-height 0.8s ease-in-out;
      -webkit-transition: max-height 0.8s ease-in-out;
      -moz-transition: max-height 0.8s ease-in-out;
      -o-transition: max-height 0.8s ease-in-out;
    }

    .drop-down-list li {
      padding: 0;
      position: relative;
    }

    .react-autosuggest__suggestions-container--open {
      border: none;
    }

    .react-autosuggest__suggestions-container {
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
      top: -7px;
      position: relative;
    }

    .react-autosuggest__suggestions-container,
    .m-auto-suggestion-list {
      overflow-y: auto;
      z-index: 1;
      top: 51px;
      position: absolute;
      max-height: 355px;
      width: 100%;
      background-color: $bgGrey;
      border: none;
      transition: max-height 0.8s ease-in-out;
      -webkit-transition: max-height 0.8s ease-in-out;
      -moz-transition: max-height 0.8s ease-in-out;
      -o-transition: max-height 0.8s ease-in-out;
    }
    .m-auto-suggestion-list {
      top: 44px;
    }

    .react-autosuggest__suggestion-match {
      color: $red;
      font-weight: 600;
    }

    .react-autosuggest__suggestions-container,
    .m-auto-suggestion-list {
      z-index: 9;
      ul {
        &.react-autosuggest__suggestions-list {
          border: 1px solid #ccc;
          border-radius: 3px;
          display: block;
          margin: 0;
          padding: 0;
          max-height: 202px;
          overflow: auto;
          li {
            padding: 10px 11px 12px 30px;
            font-size: 14px;
            border: 1px solid #e6e6e6;
            background-color: $white;
            color: $darkBlack;
            font-weight: 500;
            text-align: left;
            border-left: 0;
            border-right: 0;
            margin-bottom: 1px;
            position: relative;
            cursor: pointer;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:first-child {
              border-radius: 3px 3px 0 0;
            }
            &:last-child {
              border-radius: 0 0 3px 3px;
            }
            &:before {
              left: 9px;
              top: 4px;
              background: url(/assets/images/autocomplete-icons.png) no-repeat 0 -156px;
              content: "";
              position: absolute;
              width: 17px;
              height: 26px;
              overflow: hidden;
            }
            &.react-autosuggest__suggestion--highlighted {
              background-color: #ebf2fe;
              padding: 11px 10px 11px 31px;
              &:before {
                background-position: -18px -156px;
              }
            }
          }
        }
      }
    }
  }
}

.drop-down-list {
  li {
    padding: 0;
    position: relative;
  }
}
.citylist-input {
  padding-left: 35px !important;
}

.citylist-input {
  padding-left: 35px !important;
}

.productForm {
  width: 100%;
  padding-bottom: 10px;
  background: #f4f4f4;
  padding: 12px;
  box-sizing: border-box;
  margin-top: 10px;
}

.delivery-area-form-wrapper {
  position: relative;
  padding-bottom: 8px;
}
.delivery-area-form-wrapper .place-pin {
  top: 35px;
}
.address-filled .place-pin,
.place-pin.select-location {
  color: $red;
}
.address-location-filled.fnp-red {
  color: $red;
}
.product-form .deliverydates-selectbox-wrapper.product-input-border-shadow {
  background-color: $white;
}
.address-filled .autocomplete-input,
.autosuggest-wrapper .address-location-filled {
  background-color: $bgGrey;
  pointer-events: none;
}

.search-default-questions {
  border: 1px solid #ccc;
  border-radius: 3px;
  display: block;
  margin: 0;
  padding: 0;
  li {
    padding: 11px 11px 11px 30px;
    font-size: 14px;
    border: 1px solid #e6e6e6;
    color: $blue;
    text-align: left;
    border-left: 0;
    border-right: 0;
    margin-bottom: 1px;
    position: relative;
    cursor: pointer;
    &:first-child {
      border-radius: 3px 3px 0 0;
    }
    &:last-child {
      border-radius: 0 0 3px 3px;
    }
  }
  &.desktop-default-list {
    border: 0;
    li {
      margin-bottom: 0;
      color: $blue;
      background: $bgDarkGrey;
    }
  }
}

.deliver-input {
  border: 0;
  input {
    display: block;
    padding: 8px 13% 8px 30px;
    border-radius: 4px;
    line-height: 26px;
    font-size: 20px;
    margin: 0;
    transition: all 0.25s ease-in-out;
    height: auto;
    width: 100%;
    font-weight: 400;
    margin-bottom: 6px;
    border: 1px solid $lightGrey;
    color: $lightGrey;
    background: $white;
  }
  fieldset {
    display: none;
  }
}
.deliver-input-empty {
  input {
    margin: 0 0 0 -13px !important;
    padding: 15px 13px 13px 130px !important;
  }
}

.deliver-input {
  input {
    padding: 13px 5px 12px 38px;
  }
  input[type="text"]::-webkit-input-placeholder {
    color: $lightGrey;
    opacity: 1;
  }
  input[type="text"]::-moz-placeholder {
    color: $lightGrey;
    opacity: 1;
  }
  input[type="text"]:-ms-input-placeholder {
    color: $lightGrey;
    opacity: 1;
  }
  input[type="text"]:-moz-placeholder {
    color: $lightGrey;
    opacity: 1;
  }

  &.autocomplete-input.readOnlyInput {
    cursor: default;
    background: $bgDarkGrey;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0);
    border: 1px solid $lighterGrey;
    pointer-events: none;
  }

  &.autocomplete-input.animate,
  &.animate input {
    border: 1px solid $yellow;
    box-shadow: 0 0 5px $yellow;
    animation: focuses ease-in-out 1.5s infinite;
    -webkit-appearance: none;
    &.non-deliverable-text {
      color: $red;
      border: 1px solid $red;
    }
    &.pinCodeBorder {
      border: 1px solid $red;
      box-shadow: 0 0 5px $red;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
      //border: 0;
    }
  }
}

/* international pin*/
.place-pin-international {
  position: absolute;
  left: 12px;
  top: 13px;
  svg {
    color: $lightGrey;
    width: 24px;
    height: 24px;
  }
}
.cross-input-international {
  position: absolute;
  right: 4px;
  top: 14px;
  cursor: pointer;
  svg {
    color: $lightGrey;
    width: 24px;
    height: 24px;
  }
}
.deliver-input.error-animate {
  input {
    border: 1px solid $red;
    box-shadow: 0 0 5px $red;
    animation: errorAnimation ease-in-out 1.5s infinite;
    animation-name: errorAnimation;
  }
}
@keyframes errorAnimation {
  0% {
    box-shadow: 0 0 10px $red;
  }
  50% {
    box-shadow: 0 0 0 $red;
  }
  100% {
    box-shadow: 0 0 10px $red;
  }
}

.animate-input {
  -webkit-animation: focuses 1.5s ease-in-out infinite;
  -moz-animation: focuses 1.5s ease-in-out infinite;
  animation: focuses 1.5s ease-in-out infinite;
  box-shadow: 0 0 10px $yellow;
  border: 1px solid $orange !important;
}

.readOnlyInput {
  cursor: default;
  background: $bgDarkGrey;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0);
  border: 1px solid $lighterGrey;
}

.highlightError {
  box-shadow: 0 0 10px $red;
  border: 1px solid $red !important;
  transition: all ease-in-out 0.25s;
  -webkit-transition: all ease-in-out 0.25s;
  -moz-transition: all ease-in-out 0.25s;
  -o-transition: all ease-in-out 0.25s;
  color: rgb(229, 51, 51);
  animation: errorAnimation ease-in-out 1500ms infinite;
}

@keyframes focuses {
  0% {
    -webkit-box-shadow: 0 0 10px $yellow;
    -moz-box-shadow: 0 0 10px $yellow;
    box-shadow: 0 0 10px $yellow;
  }

  50% {
    -webkit-box-shadow: 0 0 0 $yellow;
    -moz-box-shadow: 0 0 0 $yellow;
    box-shadow: 0 0 0 $yellow;
  }
  100% {
    -webkit-box-shadow: 0 0 10px $yellow;
    -moz-box-shadow: 0 0 10px $yellow;
    box-shadow: 0 0 10px $yellow;
  }
}

.deliver-on {
  :global {
    .deliver-section-input-container {
      .MuiFormControl-root.deliver-input {
        input {
          padding: 11px 5px 12px 30px;
        }
        input[type="text"]::-webkit-input-placeholder {
          color: $lightGrey;
          opacity: 1;
        }
        input[type="text"]::-moz-placeholder {
          color: $lightGrey;
          opacity: 1;
        }
        input[type="text"]:-ms-input-placeholder {
          color: $lightGrey;
          opacity: 1;
        }
        input[type="text"]:-moz-placeholder {
          color: $lightGrey;
          opacity: 1;
        }
      }
    }
  }
}

.text-invalid {
  color: $red !important;
}

.sameDayDeliveryGiftsMobile {
  padding: 8px 0px 14px;
  font-size: 14px;
  color: $red;
  display: block;
  font-weight: 500;
  a {
    color: $blue;
  }
  span {
    margin-right: 5px;
  }
}

.delivery-input-m-site {
  input {
    text-align: start !important;
  }
}
