@import "/styles/themes/colors";

.redirectToCatalog {
  padding: 8px 0px 14px;
  text-align: center;
  color: $red;
  font-size: 12px;
  text-align: left;
  display: block;
  font-weight: 500;
  a {
    color: $blue;
    display: inline-block;
  }
  span {
    margin-right: 2px;
  }
}
.redirectToCatalogCalender {
  font-size: 16px;
  text-align: center !important;
}
.redirectToCatalogMobile {
  font-size: 14px;
  text-align: left;
}
