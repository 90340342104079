.footer {
  text-align: center;
  line-height: 1.7;
  & h6 {
    color: #555555;
    padding-bottom: 2px;
    font-weight: 500;
    margin: 0px;
  }
}
.accountFooter {
  box-shadow: 0px -2px 4px 0 rgba(0, 0, 0, 0.12);
}
