@import "/styles/themes/colors";

.calender-wrapper {
  height: 100%;

  .calender {
    padding-top: 0;
    &.mobile-calender {
      margin-top: 15px;
    }
  }

  .date-picker-heading {
    border-bottom: 1px solid $lighterGrey;
    margin-bottom: 0px;
    position: relative;
    &.mb10 {
      margin-bottom: 10px;
    }
    .calender-title {
      text-align: center;
      font-weight: 300;
      margin: 4px auto;
      display: block;
      color: $darkBlack;
      cursor: default;
      font: 500 21px / 46.001px Roboto;
    }
    .back-btn {
      left: 40px;
      top: 7px;
      position: absolute;
      svg {
        width: 30px;
        height: 30px;
        fill: $lightGrey;
        cursor: pointer;
      }
    }
  }
  .delivery-calender {
    padding: 0;
    border-radius: 10px;
  }

  :global {
    .DayPicker {
      width: 100%;
      &:focus {
        outline: none;
      }
    }
    .DayPicker-wrapper {
      &:focus {
        outline: none;
      }
    }
    .DayPicker-Day--birthday {
      background-color: #00bcd4;
      color: white;
    }
    .DayPicker-Day--monday {
      color: #00bcd4;
    }
    .delivery-calender .DayPicker-Month {
      width: 100%;
      margin: 0;
    }

    .delivery-calender .DayPicker-Weekday {
      display: table-cell;
      padding: 0.5rem;
      text-align: center;
      color: $lightGrey;
      font-size: 1.2em;
      font-weight: 400;
    }
    .delivery-calender .DayPicker-Day {
      padding: 1px 2px;
      text-align: center;
      color: $darkBlack;
      outline: none;
      font-size: 1.2em;
      line-height: 2.5em;
      font-family: roboto;
      position: relative;
      .cell-block {
        width: 42px;
        height: 42px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .date-block {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          color: $darkBlack;
          border-radius: 100%;
          line-height: 1.2;
        }
      }
    }
    .DayPicker-wrapper {
      padding-bottom: 0;
      color: $darkBlack;
    }
    .delivery-calender
      .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background: transparent;
    }
    .delivery-calender
      .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
      .cell-block
      .date-block {
      background-color: $olive;
      color: $white;
    }

    .DayPicker-Day .desktop-calender .side-to-selected-date-circle {
      border: 1px solid $olive;
      border-radius: 50%;
      background-color: white;
    }

    .DayPicker-Day .desktop-calender .left-side-to-selected-date::after {
      content: "";
      position: absolute;
      top: 3px;
      right: -37px;
      height: 88%;
      width: 100%;
      background-color: $lightYellow;
      z-index: -1;
      border-radius: 0;
    }

    .DayPicker-Day .desktop-calender .right-side-to-selected-date::before {
      content: "";
      position: absolute;
      top: 3px;
      right: 35px;
      height: 88%;
      width: 100%;
      background-color: $lightYellow;
      z-index: -1;
      border-radius: 0;
    }

    .delivery-calender .DayPicker-Day.DayPicker-Day--today .cell-block .date-block {
      border: 1px solid green;
      border-radius: 100%;
      color: green;
      line-height: 1.2;
    }

    .delivery-calender .DayPicker-Day.DayPicker-Day--disabled .cell-block .date-block {
      color: #dce0e0;
      cursor: default;
    }

    .mobile-calender .DayPicker-Day {
      padding: 0.5rem 0.1rem;
      font-size: 1.3em;
      line-height: 2em;
    }
    .mobile-calender .DayPicker-Day .cell-block {
      margin: 0 auto;
    }

    .delivery-calender .DayPicker-Day.DayPicker-Day--today.DayPicker-Day--disabled .cell-block .date-block {
      opacity: 0.3;
      border: 1px solid green;
      border-radius: 100%;
      color: green;
      line-height: 1.2;
    }

    .delivery-calender-arrow-button-container {
      position: relative;
      width: 100%;
      background: #393;
      .delivery-calender-arrow-button {
        border: 0;
        background: transparent;
        position: absolute;
        width: 100%;
        background: $white;
        top: 0;
        width: 40px;
        &.c-right {
          right: 0;
        }
        &.c-left {
          left: 0;
        }
      }
    }
    .price-info-legend-message {
      text-align: center;
      .legend-message {
        display: inline-block;
        &:before {
          content: "";
          background-image: url("https://uat-i7.fnp.com/assets/images/surge.png") !important;
          height: 25px;
          width: 25px;
          float: left;
          margin-right: 8px;
          background-size: 20px;
          background-repeat: no-repeat;
        }
      }
    }
    .adjust-calendar-footer {
      height: 60px;
      position: relative;
      background-color: $white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      bottom: 0;
      width: inherit;
    }
    .international-prod-container {
      height: 40px;
    }
    .international-prod-container-shipping-info {
      height: 50px;
    }
    .courier-prod-container {
      height: 50px;
    }
    .mobile-calender-footer {
      text-align: center;
      background-color: white;
      height: 45px;
    }
    .same-day-delivery-gifts {
      color: $red;
      font-size: 16px;
      text-align: center;
      display: block;
      position: relative;
      font-weight: 500;
      margin-top: 14px;
      margin-bottom: -44px;
      a {
        color: $blue;
      }
    }
    .DayPicker-NavBar {
      .DayPicker-NavButton {
        position: absolute;
        &.DayPicker-NavButton--prev {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid #484747;
          background: 0;
          color: #484747;
          font-size: 2em;
          text-indent: 0;
          left: 4.7em;
          top: 1px;
        }
        &.DayPicker-NavButton--next {
          right: 9.7em;
          cursor: pointer;
          background: $white !important;
          top: 1px;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid #484747;
        }
      }
    }
    .delivery-calender .DayPicker-Weekday {
      color: #484747;
      font: 300 22px Roboto;
      background: $white;
    }
    .delivery-calender .DayPicker-Day {
      font-family: Roboto;
      font-size: 18px;
      padding: 0.1rem;
      font-weight: 400;
    }

    .desktop-delivery-footer {
      background-color: $bgDarkGrey;
      border-radius: 0 0 10px 10px;
      height: 60px;
      position: absolute;
      bottom: 0;
      width: 532px;
      text-align: left;
      .b2c {
        text-align: left;
        color: $lightGrey;
        padding: 15px;
        font-size: 13px;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 3px;
        display: inline-block;
        &:before {
          content: url("https://uat-i7.fnp.com/assets/images/calendartransparent.png");
          vertical-align: -webkit-baseline-middle;
          padding-right: 5px;
        }
      }
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.side-to-selected-date-circle):not(.DayPicker-Day--outside):hover
      .cell-block {
      background-color: $olive;
      border-radius: 50%;
      .date-block:not(.side-to-selected-date-circle) {
        color: $white;
      }
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background: transparent !important;
    }

    .DayPicker {
      width: 100%;
    }
    .DayPicker-Day--birthday {
      background-color: #00bcd4;
      color: white;
    }
    .DayPicker-Day--monday {
      color: #00bcd4;
    }
    .DayPicker-Month {
      width: 85%;
      margin: 0;
    }
    .DayPicker-Caption {
      padding: 0 0.5rem;
      display: table-caption;
      text-align: center;
      margin-bottom: 0px;
      text-transform: uppercase;
    }
    .DayPicker-Caption > div {
      font-size: 1.2rem;
      color: $darkGrey;
      font-weight: 300;
      font-family: roboto;
      letter-spacing: 5px;
      text-transform: none;
      margin-bottom: 16px;
    }
    .DayPicker-Weekday {
      display: table-cell;
      padding: 0.5rem;
      text-align: center;
      color: $lightGrey;
      font-size: 1.2em;
      font-weight: 400;
    }
    .DayPicker-Day {
      padding: 1rem 0.4rem;
      text-align: center;
      color: $darkBlack;
      outline: none;
      font-size: 1.2em;
      line-height: 2.5em;
      font-family: roboto;
      position: relative;
      .cell-block {
        width: 36px;
        height: 35px;
        margin-top: 2px !important;
        margin-bottom: 2px !important;
        justify-content: center;
        align-items: center;
        .date-block {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          color: $darkBlack;
          border-radius: 100%;
          line-height: 1.2;
        }
      }
    }
    .DayPicker-Day:not(.DayPicker-Day--disabled):hover {
      .date-block:hover,
      .cell-block:hover {
        background-color: $olive;
        color: $white;
        border-radius: 100%;
        cursor: pointer;
      }
    }
    .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background: transparent;
    }

    .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--disabled) .cell-block .date-block {
      background-color: $olive;
      color: $white;
    }
    .desk-calender .DayPicker-Day.DayPicker-Day--disabled {
      pointer-events: none;
    }
    .DayPicker-Day.DayPicker-Day--disabled .cell-block .date-block {
      color: #dce0e0;
      cursor: default;
    }
    .DayPicker-Day.DayPicker-Day--today.DayPicker-Day--disabled .cell-block .date-block {
      opacity: 0.3;
      color: $darkBlack;
      background-color: $white;
      border: none;
      line-height: 1.2;
    }
    .DayPicker-Day.DayPicker-Day--disabled.DayPicker-Day--currentDate .cell-block .date-block {
      color: $olive;
      border: 1px solid $olive;
    }
    .DayPicker-Day.DayPicker-Day--today {
      font-weight: normal;
    }

    .surge-icon {
      width: 16px;
      height: 16px;
      content: "";
      background: url(/assets/images/surge.png) 0 0 no-repeat;
      background-size: 16px;
      position: absolute;
      right: -2px;
      top: -2px;
    }
    sup.surge-icon-mobile {
      position: absolute;
      top: -1px;
      left: 34px;
    }

    .datePicker .surge-icon {
      position: static;
    }

    sup.surge-icon-desktop {
      position: absolute;
      top: 1px;
      left: 28px;
    }

    .DayPicker-Day {
      font-family: Roboto;
      font-size: 18px;
      padding: 0.1rem;
      font-weight: 400;
    }

    .DayPicker-Day {
      padding: 1px 2px;
      text-align: center;
      color: $darkBlack;
      outline: none;
      font-size: 1.3em;
      line-height: 2.5em;
      font-family: roboto;
      position: relative;
      .cell-block {
        width: 42px;
        height: 42px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .date-block {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          color: $darkBlack;
          border-radius: 100%;
          line-height: 1.2;
        }
      }
    }

    .desk-calender {
      .DayPicker-Weekday {
        color: #484747;
        font: 300 22px Roboto;
        background: $white;
      }
    }
    .desk-calender {
      .DayPicker-Caption > div {
        letter-spacing: 0;
        margin-bottom: 0px;
      }
    }
    .desk-calender {
      .DayPicker-Month {
        width: 100%;
        margin: 0;
      }
    }
    .desk-calender {
      sup.surge-icon {
        left: 44px;
      }
    }
    .desk-calender {
      .DayPicker-Day {
        font-size: 1.2em;
      }
    }
  }
}
