.light-text{
  font-size: 14px;
  font-weight: 300;
}
.bold-text{
  font-weight: 500;
  margin: 0 15px;
  font-size: 14px;
}

.currency-strip > b{
  margin: 0px 15px;
}

.menu-width{
  width: 250px;
}
