@import "../../../../styles/themes/colors";
.header-style {
  display: flex;
  justify-content: space-around;
  background-color: orange;
  height: 60px;
}
.myHome {
  color: green;
}

.myHref {
  color: red;
}

.myAbout {
  color: purple;
}

.myContact {
  color: blue;
}
.align-items {
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.maintoolbar-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 85px;
  height: 100%;
  width: 0;
  border: 0;
  color: $white;
  font-size: 13px;
  align-self: center;
  position: relative;
  line-height: 1.7;
  cursor: pointer;
  &:hover {
    & > i,
    span:nth-child(2) {
      color: #f2f2f2;
    }
  }
}
