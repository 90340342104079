.logoSvg {
  background: url("/all-icons.svg");
  background-size: 520%;
  width: 190px;
  height: 60px;
  position: absolute;
  bottom: -18px;
  background-position-x: 0px;
  background-position-y: 0px;
}
