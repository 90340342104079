@import "/styles/themes/colors";

.selectShippingDesktop {
  overflow-y: auto;
  margin-top: 35px;
  .scrollPane {
    height: 302px;
    display: block;
    > ul {
      height: 100%;
      margin: 0;
      width: 100%;
      outline: none;
      li {
        max-width: 100%;
        list-style: none;
        display: table;
        height: 50px;
        width: 100%;
        margin-bottom: 20px;
        border-radius: 5px;
        padding: 0 5%;
        outline: none;
        .timeRadioLabel {
          text-align: left;
          display: flex;
          padding: 0;
          color: $darkBlack;
          line-height: inherit;
          text-decoration: none;
          outline: medium none;
          justify-content: center;
          align-items: center;
          span {
            &.leftBox {
              width: 82.9%;
              border-radius: 4px 0 0 4px;
              margin-right: 0;
              height: 50px;
              border: 1px solid #ccc;
              border-right: 0 solid $white;
              display: flex;
              border: 1px solid #ccc;
              cursor: pointer;
              .shipmentMethodContainer {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-self: center;
              }
              .shipmentMethodContainerInt {
                display: flex;
                flex-direction: column;
                width: calc(49% - 19px);
                align-self: center;
              }
              .longDescription {
                font-size: 12px;
                padding-left: 3px;
                margin-top: 3px;
                color: $lightGrey;
                width: 335px;
              }
              .leftBoxText {
                text-align: center;
                color: $darkBlack;
                font-size: 16px;
                padding-left: 3px;
                font-weight: normal;
                text-align: left;
                padding-top: 2px;
              }
              .earliestdate {
                color: $blue;
                font-size: 14px;
                padding: 14px 9px 0 5px;
                width: calc(51% - 19px);
                text-align: right;
              }
              :global {
                .MuiSvgIcon-root {
                  font-size: 1.2rem;
                }
                .MuiRadio-colorSecondary.Mui-checked {
                  color: $olive !important;
                }
              }
              :global {
                .MuiIconButton-root {
                  width: 38px;
                }
              }
            }
          }
          .inputGroupPrice {
            float: right;
            margin: 0 2px 0 0;
            border-radius: 0 3px 3px 0;
            height: 50px;
            width: 15%;
            color: $white;
            background-color: $olive;
            text-align: center;
            border: 1px solid $olive !important;
            display: flex;
            justify-content: center;
            align-items: center;
            .deliveryCost {
              text-decoration: none;
              font-size: 13px;
              font-weight: 500;
              text-align: center;
              color: $white;
              .WebRupee {
                margin: 0;
                font-size: 13px;
                font-weight: 600;
                vertical-align: middle;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
