@import "/styles/themes/colors";

.calenderNavigationArrows {
  position: relative;
  width: 100%;
  background: #393;
  .calenderArrowButton {
    border: 0;
    background: transparent;
    position: absolute;
    width: 100%;
    background: $white;
    top: 0;
    width: 40px;
    padding: 0;
  }
  .contentRight {
    right: 10px;
  }
  .contentLeft {
    left: 10px;
  }
  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.desktopCalenderNav {
  .calenderArrowButton {
    top: -12px;
    width: 40px;
    background: transparent;
    svg {
      width: 44px;
      height: 44px;
      color: #484747;
    }
  }
  .contentRight {
    right: 130px;
    cursor: pointer;
  }
  .contentLeft {
    left: 130px;
    cursor: pointer;
  }
}

.no-delivery-wrapper {
  position: relative;
}

.sameDayDeliveryGifts {
  padding: 12px 0px 14px;
  font-size: 15px;
  text-align: center;
  color: $red;
  font-size: 16px;
  text-align: center;
  display: block;
  font-weight: 500;
  a {
    color: $blue;
  }
  &.mobileDeliveryGifts {
    a {
      display: block;
      margin-top: 5px;
    }
  }
  span {
    margin-right: 5px;
  }
}

.surge-icon {
  :global {
    .material-icons {
      font-size: 20px;
      transform: rotate(23deg);
      color: #f69132;
    }
  }
}

.surgeSymbol {
  position: absolute;
  width: 15px;
  top: 0;
  right: 0;
}

.legendPriceSurgeMessage {
  color: $darkBlack;
  position: relative;
  left: 16px;
  &:before {
    position: absolute;
    left: -29px;
    bottom: 0px;
    content: "";
    background-image: url(/assets/images/surge.png) !important;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    background-size: 20px;
    background-repeat: no-repeat;
  }
}

.surgePriceMessageBlock {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.blocked-dates-toast,
.noDeliveryToast {
  position: absolute;
  width: 80%;
  left: 10%;
  background: $lightGrey;
  z-index: 999;
  color: $white;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  border-radius: 30px;
  display: none;
  transform: translateZ(0) scale(1);
  transform: translateY(50%);
  display: none;
}

.calender_footer_wrapper {
  line-height: normal;
  color: $lightGrey;
  text-align: start;
  padding: 0 20px;
  font-size: 12px;
  .shippingOptionInfo {
    color: $red;
    font-size: 16px;
    font-weight: 500;
  }
  .shippingOptionInfoSvg{
    & svg {
      margin-top: 2px;
    }
  }
  & svg {
    font-size: 14px;
    margin-right: 8px;
    transform: rotate(180deg);
  }
}

.valentineMessage {
  color: $red;
  font-size: 14px;
  text-align: center;
  display: block;
  font-weight: 400;
  margin-top: 17px;
  line-height: 4em;
  span {
    &.note-inter-prod {
      font-size: 14px;
      color: $darkBlack;
      display: block;
      padding: 8px;
      font-weight: 300;
      line-height: 1.28;
      text-align: left;
      background: $bgDarkGrey;
      width: 100%;
      &.desk-note-inter-prod {
        color: #4ca7c9;
        font-size: 12px;
        width: 95%;
        word-spacing: 1px;
        padding-top: 10px;
        text-align: center;
      }
    }
  }
  &.mobilevalentineMessage {
    line-height: 1.5;
    padding: 0 5px;
  }
}

.disableMonthArrow {
  opacity: 0.3;
  pointer-events: none;
  cursor: default !important;
}

.selectDatePopup {
  position: fixed;
  background: #ffffbc;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  z-index: 2;
  text-align: center;
  box-shadow: 0 5px 15px $lighterGrey;
  opacity: 0;
  pointer-events: none;
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
  left: 10px;
  right: 10px;
  top: 64.6px;
  height: 128px;
  p {
    line-height: 1.2;
    font-size: 16px;
    color: $darkBlack;
  }
  .buttonWrapper {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding: 10px 20px;
      margin: 0 5px;
      border: 1px solid #ddd;
      color: $darkBlack;
      background: $white;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 5px;
      font-size: 16px;
    }
  }
  .popupArrowDown {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffbc;
    position: absolute;
    bottom: -9px;
    left: 10px;
  }
}
.courier-product-msg-mobile {
  width: 100%;
  text-align: center;
  font-weight: 400;
  color: $darkBlack;
  font-size: 16px;
}
.courier-product-msg {
  width: 100%;
  text-align: center;
  font-weight: 550;
  color: $darkBlack;
}
@media (min-width: 769px) {
  .selectDatePopup {
    height: 111px;
    width: 510px;
    left: 50%;
    margin-left: -255px;
  }
}

.note-international {
  color: #4ca7c9;
  font-size: 12px;
  width: 95%;
  word-spacing: 1px;
  padding-top: 10px;
  text-align: center;
  line-height: 1;
  display: inline-block;
  letter-spacing: 0;
}

.info-details.icon {
  font-size: 19px;
  position: relative;
  top: 0;
  color: $lightGrey;
  margin-right: 3px;
  vertical-align: sub;
}
