@import "../../../../../styles/themes/colors";

.main-toolbar {
  margin: 0px auto;
  max-width: 1605px;
  width: 100%;
  position: relative;
  padding: 5px 35px;
  height: 79px;
  // background-color: #fff;
  background-color: $olive;
  & > div > a {
    line-height: 1.7;
  }

  :global {
    #main-logo {
      visibility: inherit;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      box-sizing: border-box;
      padding: 0px;
      border: none;
      margin: auto;
      display: block;
      width: 0px;
      height: 0px;
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
    }
  }
}
.cart-account-drop-down {
  justify-content: space-between;
  display: flex;
}
