@import "/styles/themes/colors";

.bottomIcons {
  bottom: 0;
  transition: bottom 0.5s;
  position: fixed;
  width: 100%;
  z-index: 99;
  text-align: center;
  background: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.bottomIconsPlp {
  bottom: 0;
  transition: bottom 0.5s;
  position: fixed;
  width: 100%;
  z-index: 99;
  text-align: center;
  background: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  &.catSort button:first-child {
    border-right: 1px solid #d5d5d5;
  }
  &.catSort button:only-child {
    border-right: none;
  }
  &.catSort button span {
    flex-direction: row !important;
  }
  &.catSort button span span {
    font-size: 14px;
    margin-left: 5px;
    color: $lightGrey;
    text-align: left;
  }
  :global {
    .MuiBottomNavigationAction-root.Mui-selected span {
      font-size: 0.75rem;
    }
    .MuiBottomNavigationAction-root button {
      padding: 0px;
    }
    .MuiBottomNavigationAction-root {
      max-width: 100%;
    }
  }
}
.hidden {
  bottom: -200px;
}
.displayNone {
  display: none;
}

.sortPopUp {
  position: fixed;
  bottom: 0;
  min-height: 200px;
  width: 100%;
  max-width: initial;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  z-index: 1006;
  background-color: #fefefe;
  margin-right: auto;
  overflow-y: auto;
  :global {
    .MuiSvgIcon-root {
      font-size: 18px;
    }
    .MuiRadio-root {
      padding: 0px 7px 0px 2px;
    }
    .MuiButtonBase-root {
      padding-top: 5px;
    }
    .MuiFormControlLabel-root {
      padding: 8px;
      margin-left: -4px;
      align-items: flex-start;
    }
    .MuiFormGroup-root {
      padding: 0rem 1rem 1rem 1rem;
    }
  }
}

.sortHeading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px 15px 14px 24px;
}
.MuiFormControl-root {
  width: 100%;
}
.MuiTypography-root {
  font-size: 14px;
}
.close-popup {
  color: #575454 !important;
  line-height: 0.9 !important;
  font-size: 28px !important;
  font-weight: 400 !important;
}

.Mui-checked + span {
  font-weight: bold !important;
}

.bottomIcons .Mui-selected span:first-child ::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: green;
  left: 0;
}
.sortIconSelected {
  position: relative;
}
.bottomIconsPlp .Mui-selected span:first-child ::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: none !important;
  left: 0;
}

.bottomIcons .MuiBottomNavigationAction-root,
.bottomIconsPlp .MuiBottomNavigationAction-root {
  padding: 6px 5px 8px;
}
.bottomIconsPlp .bottomIcons.catSort button:first-child {
  border-right: 1px solid #d5d5d5;
}

.bottomIcons,
.bottomIconsPlp {
  :global {
    .MuiBottomNavigationAction-root span {
      color: $lightGrey;
    }
    .MuiSvgIcon-root {
      font-size: 24px;
      color: $lightGrey;
    }
    .MuiBottomNavigation-root {
      height: 3.125rem;
    }
    .MuiButtonBase-root {
      max-width: unset;
      min-width: unset;
    }
    .MuiBottomNavigationAction-root.Mui-selected {
      .MuiSvgIcon-root {
        color: $olive;
      }
      span {
        font-size: 12px;
        color: $olive;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: $olive;
        left: 0;
      }
    }
  }
}
.chatLabel {
  span {
    width: max-content;
  }
}
.categoriesSort {
  height: 100%;
}
