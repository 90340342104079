@import "/styles/themes/colors";

.modalBody {
  width: 100%;
  height: calc(100vh - 45px);
  background: $white;
}
.modalBodySelectCountry {
  overflow: hidden;
}
.mobileDeliveryModalSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10000000;
  background: hsla(0, 0%, 99%, 0.8);
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0px;
}

.modal-container {
  opacity: 1;
  flex: 0 1 auto;
  display: flex;
  position: relative;
  max-height: 90vh;
  flex-direction: column;
  width: 100%;
  margin: 0;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
  background: #7f7f7f;

  .modal-container-inner {
    width: 100%;
    background: $white;
    position: relative;
    top: 0;
    left: 0;
  }
  &.animated {
    .modal-container-inner {
      animation: square 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      -webkit-animation: square 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
}

@keyframes square {
  0% {
    top: 50%;
    left: 0;
    animation-timing-function: ease-in;
  }

  100% {
    top: 0px;
    left: 0;
  }
}
@-webkit-keyframes square {
  0% {
    -webkit-top: 50%;
    -webkit-left: 0;
    -webkit-animation-timing-function: ease-in;
  }

  100% {
    -webkit-top: 0px;
    -webkit-left: 0;
  }
}
