@import "/styles/themes/colors";

.productContent {
  width: 100%;
  padding: 0 30px 0 0;
  .productName {
    margin-bottom: 5px;
    font-size: 18px;
    color: $darkGrey;
    font-weight: 400;
    text-overflow: ellipsis;
    margin-top: 0;
    padding-top: 0;
  }
}
.categoryReviewSummary {
  margin: 5px 0 0 0;
  .ratingSec {
    :global {
      .product-rnr {
        justify-content: flex-start;
        .product-rating {
          color: #feb614;
          font-size: 12px;
          display: inline-block;
          padding-right: 5px;
        }
        .product-reviews {
          color: $blue;
          font-size: 12px;
          display: inline-block;
          cursor: pointer;
        }
        .MuiRating-root {
          position: relative;
          margin-right: 10px;
        }
      }
    }
  }
}

.productPrice {
  position: relative;
  display: flex;
  flex-direction: row;
  .crossPrice {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    text-align: left;
    color: $lightGrey;
    font-weight: 400;
    .oldPrice {
      text-decoration: line-through;
    }
    .offPrice {
      color: $green;
    }
  }
  .priceBlock {
    .priceDiscountBlock {
      :global {
        .odometer.odometer-auto-theme,
        .odometer.odometer-theme-default {
          font-family: Roboto, sans-serif;
        }
      }
      .odometerPrice {
        min-height: 60px;
        text-align: left;
        box-sizing: border-box;
        font-size: 48px;
        color: $darkBlack;
        font-weight: 300;
        display: flex;
        .currencySymbol {
          font-size: 20px;
          vertical-align: top;
          position: relative;
          top: 7px;
          font-weight: 400;
          margin-right: 5px;
        }
      }
    }
  }
  #show-surge-info {
    display: none;
  }
  .surgeInfoDesktop {
    position: relative;
  }
}

.infoTooltip {
  display: none;
  font-size: 14px;
  padding: 7px 10px 10px;
  text-align: left;
  top: 25px;
  left: 0px;
  z-index: 1;
  background: $white;
  position: absolute;
  -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  line-height: 20px;
  border-radius: 4px;
  font-weight: 400;
  width: max-content;
  overflow-wrap: break-word;
  overflow: hidden;
  color: $darkBlack;
  max-width: 300px;
}

.offers-available-btn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 128px;
  height: 24px;
  background-color: $olive;
  color: $white;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px;
  position: absolute;
  cursor: pointer;
  bottom: 5px;
  left: 156.438px;
}

.taxMessage {
  padding-bottom: 7px;
  padding-left: 25px;
  font-size: 12px;
  color: $lightGrey;
}

.viewMore {
  margin-bottom: 8px;
  display: inline-block;
  .blueText {
    color: $blue;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}

.surge-icon {
  color: #f69132;
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: inline-flex;
  margin-right: 5px;

  i {
    transform: rotate(23deg);
    font-size: 18px;
  }

  &::before {
    display: none;
    content: "";
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-top: solid 10px $white;
    position: absolute;
    top: auto;
    bottom: -10px;
    right: auto;
    float: none;
    left: 0px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    z-index: 9;
    width: 0;
    height: 0;
    line-height: 20px;
    -webkit-filter: drop-shadow(0 3px 2px #ddd);
  }

  &:hover {
    .infoTooltip {
      display: block;
    }
    &::before {
      display: block;
    }
  }
}

.offer-surge-price-wrapper {
  margin-top: 10px;
}

.offers-section {
  margin-left: 15px;
}
.main-txt {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 4px;
  margin-top: -5px;
  color: $darkBlack;
}
.main-text-cutOff {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 4px;
  margin-top: -5px;
  color: $darkBlack;
}
.price-box {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  color: $darkBlack;
}
.price-box p {
  font-size: 12px;
  font-weight: 500;
  color: $lightGrey;
}
.price-box span {
  color: $darkBlack;
}
.info {
  font-size: 11px;
  color: $lighterGrey;
  display: inline-block;
  padding-bottom: 2px;
}
.discount {
  font-size: 10px;
  font-weight: 500;
  color: $green;
  padding-top: 5px;
  padding-bottom: 3px;
  margin-top: 10px;
  border-top: 1px solid #d5d5d5;
}
.currency-msg {
  padding-top: 2px;
  color: $lightGrey;
  font-size: 12px;
}
.price-box .cross {
  text-decoration: line-through;
}

.info-details.icon {
  font-size: 18px;
  position: relative;
  top: 0;
  cursor: pointer;
  color: $lightGrey;
  margin-left: 6px;
  vertical-align: top;
}
.info-details.icon.align-bottom {
  vertical-align: middle;
}
.info-details.icon.hide-info-btn {
  display: none;
}
.currencySymbol {
  margin-right: 2px;
}
