// Starting with _ , so can be imported to other SCSS files.

// $primary: "blue";
// $bgGrey: #f8f8f8;
$black: #222;
// $black100: #0a0a0a;
// $white: #ffffff;
// $blue: #2178cf;
// $green: #008539;
// $bgDarkGrey: #f2f2f2;
// $defaultBorderRadius: 4px;
// $lightGrey: #555555;
// $lighterGrey: #999;
$greyish: #d5d5d5;
// $orange: #e87325;
// $red: #e03131;
// $olive: #7C8035;

// new re-branding colors
$olive: #7d8035;
$lightOlive: #707428;
$green: #008539;
$orange: #e87325;
$lightOrange: #e87a25;
$blue: #2178cf;
$celticBlue: #2b65d20f;
$red: #e03131;
$darkBlack: #222222;
$darkGrey: #3d3d3d;
$lightGrey: #555555;
$lighterGray: #555;
$extraLightGrey: #757575;
$greyishBlack: #444444;
$whitishGrey: #e0e0e0;
$lightBluishGrey: #dae2ed;
$whiteGrey: #eeeeee;
$yellow: #ff9212;
$lightYellow: #f3f5cd;
$white: #ffffff;
$bgGrey: #f8f8f8;
$black100: #0a0a0a;
$bgDarkGrey: #f2f2f2;
$defaultBorderRadius: 4px;
$lighterGrey: #999999;
$bgLightGrey: #ebf2fe;
$silver: #ccc;
$lowGrey: #d5d5d5;
$dullBlack: #191a0b;
$lowWhite: #cccccc;
$darkRed: rgb(229, 51, 51);
$creamWhite: #f5f5f5;
$black111: #111;
$black000: #000;
$creamLight: #c5c5c5;
$lightgreyishGreen: #c0c398;
$darkGreyishGreen: #7d8040;
$lightRed: #ff0000;
$fontFamily: "Roboto";
$fontStyle: normal;
$textTransform: capitalize;
$fontWeight400: 400;
$fontWeight500: 500;
$borderGrey: #e2e2e2;
$whitish: #f4f4f4;
$lightGrayishYellow: #e9e9e7;
$slateGrey: #708090;
$darkgrey: #a9a9a9;
$lightGreen: #038539;
$greyBlue: #f2f6fc;
$dullCharcoal: #444;
$woodBine: #797c31;
$lighterYellow: #feffb9;
$lightBlack: #1c2025;
$fnpGreen: #737530;
