@import "../../../../../styles/themes/colors";

.header-search {
  display: inline-flex;
  position: relative;
  color: black;
  & > button:nth-child(2) {
    cursor: pointer;
    position: absolute;
    right: 50px;
    top: 10px;
    background: #fdfdfd;
    display: none;
    border: none;
    padding: 0px;
    i {
      font-size: 18px;
    }
  }
  &.close-icon > button:nth-child(2) {
    display: inline-block;
  }
  :global {
    #header-search-input {
      font-weight: normal;
      color: $darkBlack;
      font-size: 13px;
      font-family: arial;
      width: 350px;
      display: inline-block;
      height: 40px;
      border: 0;
      border-radius: 4px 0 0 4px;
      background: #fdfdfd;
      box-shadow: none;
      transition: all ease-in-out 0.25s;
      padding: 12px;
      margin: 0;
      box-sizing: border-box;
      &:focus {
        outline: 0;
      }
    }
  }
}

@mixin icon-class($icon-name) {
  & a {
    display: inline-block;
    width: 100%;
    padding: 15px;
    &:before {
      content: $icon-name;
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 20px;
      line-height: 1;
      vertical-align: middle;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      overflow-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      margin-right: 10px;
      max-width: 25px;
      overflow: hidden;
    }
  }
}

.menu-container {
  color: rgb(34, 34, 34);
  position: absolute;
  left: 0;
  top: 105%;
  min-width: 389px;
  z-index: 100;
  background: rgba(white, 0.9);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  li:hover {
    background: $bgGrey;
  }

  & > ul:nth-child(2) {
    background: white;
    margin: 0px;
    & > li {
      font-size: 14px;
      line-height: 1.6;

      cursor: pointer;
      .search-icon {
        @include icon-class("search");
      }
      .restore-icon {
        @include icon-class("restore");
      }
    }
  }
}

.menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 1.6;
  cursor: pointer;
  i {
    margin-right: 10px;
    font-size: 20px;
    vertical-align: middle;
  }
  a,
  i {
    padding: 0;
    background: none;
  }
}

.menuitem-heading {
  padding: 12px 15px;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  color: $lightGrey;
  background: rgb(248, 248, 248);
  & > i {
    font-size: 24px;
    margin-right: 0;
  }
}

.search-button {
  cursor: pointer;
  width: 40px;
  border-radius: 0 4px 4px 0;
  background: #fdfdfd;
  border: 0;
  z-index: 1;
  margin: 0;
  padding: 0;
  outline: none;
  i {
    font-size: 24px;
    color: #555555;
    vertical-align: middle;
  }
  &:hover,
  &:focus {
    background: #fdfdfd;
  }
}
.spinner {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  top: 50%;
  z-index: 999;
  margin: -16px 0 0 -16px;
  border: 4px rgba(0, 0, 0, 0.25) solid;
  border-top: 4px $olive solid;
  border-radius: 50%;
  animation: spCircRot 0.4s infinite linear;
  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
.loader {
  display: flex;
  position: relative;
  left: 60px;
  bottom: 8px;
}
.hide-div {
  display: none;
}
.search-displayed {
  color: $blue;
}
