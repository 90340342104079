@import "/styles/themes/colors";

.progress {
  background: #dd771f none repeat scroll 0 0;
  border: medium none;
  height: 52px;
  left: 0;
  position: absolute;
  border-radius: 5px;
  width: 100%;
  margin: 0;
  top: 0;
}
.progress.active .progress-bar {
  animation-name: progress-bar-stripes;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}
.progress-button .progress-inner {
  position: absolute;
  left: 0;
}
.progress-striped .progress-bar {
  background-image: linear-gradient(
    -45deg,
    hsla(0, 0%, 100%, 0.5) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.5) 0,
    hsla(0, 0%, 100%, 0.5) 75%,
    transparent 0,
    transparent
  );
  background-size: 40px 40px;
  width: 100%;
  opacity: 1;
  pointer-events: none;
}

.progress-bar {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
  float: left;
  font-size: 12px;
  height: 100%;
  line-height: 20px;
  text-align: center;
  transition: width 0.6s ease 0s;
}

.login_content {
  display: flex;
  text-align: center;
  z-index: 9;
  font-size: 18px;
  font-weight: 500;
  color: $white;
}

@media (min-width: 960px) {
  .login_content {
    font-weight: 500;
  }
}

.shopping_content {
  display: flex;
  text-align: center;
  z-index: 9;
  font-size: 18px;
  font-weight: 500;
  color: $darkBlack;
}
.shopping_progress {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  height: 52px;
  left: 0;
  position: absolute;
  width: 100%;
  margin: 0;
  top: 0;
}
.login_progress {
  background: #dd771f none repeat scroll 0 0;
  border: medium none;
  left: 0;
  position: absolute;
  width: 100%;
  margin: 0;
  top: 0;
  border-radius: 4px;
  height: 100%;
}
@keyframes progress-bar-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}
