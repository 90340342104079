@import "/styles/themes/colors";

.timeslotContainer {
  overflow-y: auto;
  .scrollPane {
    height: 302px;
    display: block;
    width: 100%;
    outline: 0;
    ul {
      height: 100%;
      margin: 0;
      width: 100%;
      li {
        max-width: 100%;
        list-style: none;
        .midNightText {
          font-size: 14px;
          color: $lightGrey;
          font-weight: 500;
          text-align: center;
          margin-top: 3px;
          svg {
            width: 14px;
            height: 14px;
            margin-right: 2px;
            position: relative;
            top: 3px;
          }
        }
        .timeSlotTitle {
          text-align: center;
          display: block;
          font-size: 20px;
          margin: 20px 0;
          color: $darkBlack;
        }

        ul {
          &.slot {
            margin: 0 auto;
            text-align: center;
            width: 100%;
            list-style-type: none;
          }
          li {
            &.timeSlotTable {
              margin: 15px auto;
              border: 1px solid #ccc;
              border-radius: 5px;
              width: 50%;
              &.select-method {
                border: 1px solid $olive;
              }
            }
            .timeRadioLabel {
              text-align: left;
              display: flex;
              padding: 0;
              color: $darkBlack;
              line-height: inherit;
              text-decoration: none;
              outline: medium none;
              justify-content: center;
              align-items: center;
              span {
                &.leftBox {
                  width: 60%;
                  border-radius: 4px 0 0 4px;
                  margin-right: 0;
                  height: 45px;
                  display: flex;
                  .timeSlotRange {
                    text-align: center;
                    font-size: 12px;
                    padding: 16px 0 0 3px;
                    font-weight: normal !important;
                    letter-spacing: 1px;
                    color: $darkGrey;
                    cursor: pointer;
                    display: block;
                  }
                  :global {
                    .MuiSvgIcon-root {
                      font-size: 1.2rem;
                    }
                    .MuiRadio-colorSecondary.Mui-checked {
                      color: $olive !important;
                    }
                  }
                }
              }
              .input-group-price {
                float: right;
                margin: 0 2px 0 0;
                border-radius: 0 3px 3px 0;
                height: 50px;
                width: 15%;
                color: $white;
                background-color: $olive;
                padding: 0.6em 0.3em;
                display: inline-block;
                text-align: center;
                border: 1px solid $olive !important;
                display: flex;
                justify-content: center;
                align-items: center;
                .delivery-cost {
                  text-decoration: none;
                  font-size: 13px;
                  font-weight: 500;
                  text-align: center;
                  color: $white;
                  .WebRupee {
                    margin: 0;
                    font-size: 0.9em;
                    font-weight: 600;
                    vertical-align: middle;
                    line-height: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
