/* Slider */

$slick-font-family: "slick" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-wrapper-calendar {
  :global {
    .slick-prev,
    .slick-next {
      position: absolute;
      display: block;
      height: 20px;
      width: 20px;
      line-height: 0px;
      font-size: 0px;
      cursor: pointer;
      background: transparent;
      color: transparent;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      padding: 0;
      border: none;
      outline: none;
      &:hover,
      &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
      }
      &:before {
        font-family: $slick-font-family;
        font-size: 20px;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .slick-prev {
      left: -25px;
      [dir="rtl"] & {
        left: auto;
        right: -25px;
      }
      &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
          content: $slick-next-character;
        }
      }
    }

    .slick-next {
      right: -25px;
      [dir="rtl"] & {
        left: -25px;
        right: auto;
      }
      &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
          content: $slick-prev-character;
        }
      }
    }

    /* Dots */

    .slick-dotted.slick-slider {
      margin-bottom: 30px;
    }

    .slick-dots {
      position: absolute;
      bottom: -25px;
      list-style: none;
      display: block;
      text-align: center;
      padding: 0;
      margin: 0;
      width: 100%;
      li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
          border: 0;
          background: transparent;
          display: block;
          height: 20px;
          width: 20px;
          outline: none;
          line-height: 0px;
          font-size: 0px;
          color: transparent;
          padding: 5px;
          cursor: pointer;
          &:hover,
          &:focus {
            outline: none;
            &:before {
              opacity: $slick-opacity-on-hover;
            }
          }
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: $slick-dot-character;
            width: 20px;
            height: 20px;
            font-family: $slick-font-family;
            font-size: $slick-dot-size;
            line-height: 20px;
            text-align: center;
            color: $slick-dot-color;
            opacity: $slick-opacity-not-active;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }
        &.slick-active button:before {
          color: $slick-dot-color-active;
          opacity: $slick-opacity-default;
        }
      }
    }

    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
      position: relative;
      overflow: hidden;
      display: block;
      margin: 0;
      padding: 0;

      &:focus {
        outline: none;
      }

      &.dragging {
        cursor: pointer;
        cursor: hand;
      }
    }
    .slick-slider .slick-track,
    .slick-slider .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    .slick-track {
      position: relative;
      left: 0;
      top: 0;
      display: block;

      &:before,
      &:after {
        content: "";
        display: table;
      }

      &:after {
        clear: both;
      }

      .slick-loading & {
        visibility: hidden;
      }
    }
    .slick-slide {
      position: initial;
      float: left;
      visibility: hidden;
      height: 100%;
      min-height: 1px;
      [dir="rtl"] & {
        float: right;
      }
      img {
        display: block;
      }
      &.slick-loading img {
        display: none;
      }

      &.dragging img {
        pointer-events: none;
      }

      .slick-initialized & {
        display: block;
      }

      .slick-loading & {
        visibility: hidden;
      }

      .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
      }
    }
    .slick-arrow.slick-hidden {
      display: none;
    }
    .slick-active {
      visibility: visible;
      transition-property: visibility;
      transition-delay: 0.4s;
    }
  }
}
