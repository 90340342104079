@import "/styles/themes/colors";

.desktopModal {
  :global {
    .deliver-section-input-container {
      .pin-icon {
        top: 11px;
      }

      .react-autosuggest__container,
      .MuiFormControl-root.deliver-input.animate {
        input {
          border: 1px solid $yellow;
          box-shadow: 0 0 5px $yellow;
          animation: c ease-in-out 1.5s infinite;
          &:focus {
            outline: 0;
            box-shadow: none;
            //border: 0;
          }
        }
      }

      .react-autosuggest__container input,
      .MuiFormControl-root.deliver-input.animate input {
        width: 100%;
        min-height: 38px;
        padding: 0 30px;
        font-size: 16px;
        border-radius: 4px;
        margin: 0;
        background: $white;
      }
    }

    .modal-date-time-picker-wrapper {
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      .modal-date-time-picker-container {
        width: 532px;
        min-height: 450px;
        height: auto;
        padding: 0;
        top: 70px;
        text-align: center;
        border-radius: 10px;
        background: $white;
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 auto;
        box-shadow: 2px 4px 5px 0 rgba(11, 11, 12, 0.2);
        display: flex;
        outline: none;
        .modal-fullscreen-container-inner {
          width: 100%;
          height: 100%;
          flex-direction: column;
          .modal-date-time-picker-header {
            width: 100%;
            height: 0;
            background: transparent;
            position: relative;
            span {
              &.close {
                position: absolute;
                right: 10px;
                top: 13px;
                cursor: pointer;
                z-index: 99;
                svg {
                  width: 30px;
                  height: 30px;
                  fill: $lightGrey;
                }
              }
            }
          }
          :global {
            .slick-slide div {
              outline: none;
            }
            .slick-prev,
            .slick-next {
              display: none !important;
            }
          }
        }
      }
      &.not-india-modal {
        .modal-date-time-picker-container {
          width: 532px;
          height: 447px;
          top: calc(calc(100vh - 447px) / 2);
          .modal-fullscreen-container-inner {
            .modal-fullscreen-body {
              .modal-body-inner {
                padding: 30px;
                .deliver-action {
                  .delivery-header {
                    margin-bottom: 8px;
                    h3 {
                      &.choose-header {
                        font-size: 22px;
                        font-weight: 500;
                      }
                    }
                    span {
                      &.area-header {
                        display: block;
                        color: $lightGrey;
                        font-size: 14px;
                        padding: 8px 0 0;
                      }
                    }
                  }
                  .deliver-section-input-container {
                    .pin-icon {
                      top: 11px;
                    }
                    .react-autosuggest__container input,
                    .MuiFormControl-root.deliver-input.animate input {
                      width: 100%;
                      min-height: 38px;
                      padding: 0 30px;
                      font-size: 16px;
                      border-radius: 4px;
                      margin: 0;
                      background: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
      :global {
        .location-modal {
          &.modalContainer {
            width: 460px;
            .modal-fullscreen-container-inner {
              .modal-fullscreen-body {
                .modal-body-inner {
                  padding: 30px;
                  .deliver-action {
                    .delivery-header {
                      margin-bottom: 8px;
                      h3 {
                        &.choose-header {
                          font-size: 22px;
                          font-weight: 500;
                        }
                      }
                      span {
                        &.area-header {
                          display: block;
                          color: $lightGrey;
                          font-size: 14px;
                          padding: 8px 0 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.personalized-ui {
        .modal-date-time-picker-container {
          width: 740px !important;
          height: 480px !important;
        }
        #imagePersonalization {
          width: 740px;
          height: 480px;
          top: 15%;

          .mugPic {
            width: 100%;
          }
        }
        .desk-personalized-heading {
          text-align: left;
          padding: 14px 25px;
          font-weight: 500;
          line-height: 16px;
        }
      }
      &.multiple-photo-modal {
        .multi-image-heading {
          font-size: 16px;
          font-weight: 500;
          padding: 14px 25px;
          border-bottom: 1px solid #d5d5d5;
          background: $bgDarkGrey;
          border-radius: 8px 8px 0 0;
        }

        .modal-date-time-picker-container {
          width: 700px;
          min-height: 270px;
          height: auto;
          border-radius: 8px;
          background: $bgDarkGrey;
          text-align: center;
          overflow: hidden;
          .photos-action-container {
            border-radius: 8px;
            height: auto;
            overflow: hidden;
            padding-top: 4px;
            padding-bottom: 4px;
            .all-photos {
              margin: 15px 22px;
              width: auto;
              justify-content: center;
              .photo-root {
                width: 85px;
                height: 85px;
                margin: 0 5px;

                .photo-inner-container {
                  opacity: 1;
                  .inner-text {
                    font-size: 28px;
                    color: #979797;
                    font-weight: 300;
                  }
                }
              }
            }
            .h2-outer {
              border-bottom: 1px solid #dfdfdf;
              width: 65%;
              margin: 0 0 10px 0;
              .span-inner {
                padding: 0 40px;
              }
            }
            .multi-photo-error {
              font-size: 12px;
              padding: 5px 0;
              margin-top: 10px;
              width: auto;
              svg {
                position: relative;
                top: -2px;
                margin-right: 2px;
              }
            }
            .continue-button-container {
              box-shadow: 0 0 7px 0 #e0dcdc;
              padding: 15px;
              margin-top: 0;
              margin-top: 15px;
              button {
                font-weight: 500;
                min-height: 36px;
                pointer-events: auto;
                max-width: 142px;
                font-size: 12px;
                border: transparent;
                border-radius: 4px;
                padding: 8px;
                height: auto;
              }
            }
            .select-photos-button {
              height: 36px;
              padding: 8px;
              font-weight: 500;
              width: 170px;
            }
          }
        }
      }
      .delivery-modal-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 10000000;
        background: rgb(0, 0, 0);
        opacity: 0.6;
        width: 100%;
        height: 100%;
        top: 0px;
      }
    }
  }
}

@keyframes c {
  0% {
    box-shadow: 0 0 10px $yellow;
  }
  50% {
    box-shadow: 0 0 0 $yellow;
  }
  to {
    box-shadow: 0 0 10px $yellow;
  }
}
