@import "../../../styles/themes/colors";

.scroll-bar {
  ::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, 0.09);
    border-radius: 100px;
  }

  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  ::-webkit-scrollbar-thumb:vertical {
    width: 10px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    min-height: 10px;
  }
  ::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 100px;
  }
}
.header-strip {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background: $olive;
  z-index: 1000;
  transition: all 0.3s ease 0s;
  will-change: transform;
}
.desktop-main {
  position: relative;
  padding: 146px 0px 0px;
  min-height: calc(100vh - 409px);
  align-items: inherit;
}
.main-error-page {
  padding: 0px;
  background: #f8f8f8;
}
.header-strip.hide {
  transform: translateY(-100%);
}
.sticky {
  position: fixed;
  top: 0px;
  z-index: 1000;
  max-width: 1530px;
  width: 100%;
  margin-top: 0px;
  @media screen and (max-width: 1600px) {
    padding: 0 35px;
    max-width: 1600px;
    left: 0;
  }
  @media screen and (max-width: 1530px) {
    padding: 0 35px;
    left: 0;
  }
}
.sticky-below-header {
  top: 144px;
  position: fixed;
  z-index: 10;
  max-width: 1530px;
  width: 100%;
  margin-top: 0;
  @media screen and (max-width: 1600px) {
    padding: 0 35px;
    max-width: 1600px;
    left: 0;
  }
  @media screen and (max-width: 1530px) {
    padding: 0 35px;
    left: 0;
  }
}
