@import "../../../../../styles/themes/colors";

.megamenu-component {
  :global {
    #navmenubar {
      text-align: left;
      background: $bgGrey;
      font-size: 16px;
      max-height: 45px;
      transition: all 0.3s;

      &.hide {
        overflow: hidden;
        max-height: 0;
      }
    }
    #navmenubar .header-wrapper {
      & > ul > li:first-child a {
        color: $olive;
      }
      & h3.upcoming {
        color: $olive;
      }
      & > div.opened {
        display: block;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 22px 40px rgba(0, 0, 0, 0.2);
      }
    }

    .new {
      background: $red;
      color: $white;
      padding: 1px 5px;
      border-radius: 20px;
      font-size: 8px;
      position: absolute;
      left: 8px;
      text-transform: uppercase;
      top: 2px;
    }
    .header-wrapper {
      transition: all ease-in-out 0.25s;
      width: 100%;
      position: relative;
      margin: 0 auto;
      padding: 0px 35px;
      max-width: 1600px;
      & .opened {
        background-color: $white;
      }
      & > div {
        border-radius: 0 0 4px 4px;
        display: none;
        padding: 0;
        background-color: $white;
        position: absolute;
        right: 35px;
        left: 35px;
      }
    }
    .header-wrapper > ul {
      white-space: nowrap;
      display: inline-block;
      font-size: 16px;
      width: 1200px;
      & li {
        display: inline-block;
        border-radius: 4px 4px 0 0;
        cursor: pointer;
        max-height: 45px;
      }
      & a {
        color: $darkBlack;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 13px;
        position: relative;
        display: block;
        outline: 0;
        padding: 13px 8px;
        &:hover {
          transition: all 0.3s ease;
          color: $darkBlack;
          background: $white;
        }
        & span i {
          display: inline;
          line-height: 13px;
          font-size: 16px;
          margin-left: 5px;
          vertical-align: middle;
        }
      }
    }

    .sub-navmenubar {
      float: left;
      width: 100%;
      display: table;
      & section {
        width: 180px;
        display: table-cell;
        text-align: left;
        vertical-align: top;
        margin: 0 5px;
        padding: 10px;
        &:nth-child(2n + 2) {
          background: #fafafa;
        }
        & > ul {
          padding: 0;
          height: 365px;
          overflow-y: auto;
          display: block;
          li {
            display: block;
          }
          & a {
            text-decoration: none;
            display: block;
            padding: 5px;
            color: $darkBlack;
            font-size: 13px;
            font-weight: 400;
            text-transform: none;
            position: relative;
            line-height: 1;
            i {
              font-size: 16px;
              position: relative;
              right: 1%;
              opacity: 0;
              bottom: -4px;
              transition: all ease-in-out 0.25s;
            }
            &:hover {
              color: $blue;
              i {
                right: 0;
                opacity: 1;
              }
            }
          }
        }
      }
      & h3 {
        color: $darkBlack;
        font-size: 14px;
        font-weight: 300;
        margin: 0;
        padding: 5px;
        line-height: 1.4;
        text-transform: uppercase;
      }
    }
    ul {
      margin-bottom: 0px;
    }
  }
}
