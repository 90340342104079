.more-info-strip {
  & > b {
    margin-right: 4px;
  }
  & :after {
    top: 26px;
    right: 27px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
  }
}
.arrow {
  right: 27px;
  top: 26px;
  border: 7px;
  color: #fff;
}
.bold-text {
  font-weight: 500;
  margin-right: 4px;
  font-size: 14px;
}
.menu-width {
  width: 210px;
}
.more-info-items {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  color: black;
  & i {
    color: rgb(102, 102, 102);
    margin-right: 10px;
    font-size: 16px;
  }
}
