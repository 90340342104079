@import "/styles/themes/colors";

.surgePriceContainer {
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1500;
  position: fixed;
  .innerContainer {
    animation: square 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    max-width: 960px;
    flex: 0 1 auto;
    margin: 32px;
    display: flex;
    position: relative;
    max-height: 90vh;
    flex-direction: column;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.2),
      0px 9px 46px 8px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background-color: $white;
    margin: 0;
    width: 90%;
    .priceContent {
      padding: 24px 0;
      flex: 1 1 auto;
      overflow-y: auto;
      .textCenter {
        text-align: center;
        .surgeTitle {
          display: block;
          font-size: 20px;
          text-transform: uppercase;
          color: $darkBlack;
        }
        .diffPrice {
          margin-top: 20px;
          position: relative;
          .surgeIcon {
            position: absolute;
            width: 25px;
            height: 25px;
            background: $yellow;
            border-radius: 50%;
            line-height: 34px;
            top: -13px;
            left: 45%;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
            img {
              position: absolute;
              left: 7px;
              top: 0;
              animation: surgeAnimation 0.3s 0.2s infinite;
              max-width: 100%;
              border-style: none;
            }
          }
          .innerPriceChange {
            display: inline-block;
            padding: 5px;
            background: $olive;
            border-radius: 50px;
            width: 77%;
            div {
              display: inline-block;
              width: 48%;
              color: $white;
              font-size: 26px;
              font-weight: 600;
              &.normalPrice {
                text-decoration: line-through;
                color: #55ef97;
                top: auto;
                margin: 0;
                text-align: center;
              }
              span {
                font-size: 24px;
                font-weight: 400;
                margin-top: 3px;
              }
              .priceSymbol {
                display: inline-block;
                font-family: Roboto, sans-serif;
                margin-right: 5px;
                vertical-align: bottom;
                margin-top: 0;
                color: $darkBlack;
              }
            }
          }
        }
        .surgeMessage {
          margin-top: 30px;
          font-size: 16px;
          color: $darkBlack;
          line-height: 26px;
        }
        .surgePriceButtons {
          margin: auto;
          margin-top: 30px;
          width: 80%;
          .continueButton {
            background: #dd771f none repeat scroll 0 0;
            border: medium none;
            height: 52px;
            border-radius: 0;
            width: 100%;
            font-weight: 500;
            margin: 0;
            color: $white;
            text-transform: uppercase;
            font-size: 18px;
            box-shadow: none;
            border-radius: 4px;
            margin-bottom: 20px;
          }
          .nonSurgeButton {
            color: rgba(0, 0, 0, 0.87);
            padding: 11px 16px;
            font-size: 14px;
            min-width: 88px;
            box-sizing: border-box;
            min-height: 36px;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-weight: 500;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            line-height: 1em;
            border-radius: 2px;
            text-transform: uppercase;
            background-color: transparent;
            border: 0;
            cursor: pointer;
            display: inline-flex;
            outline: none;
            position: relative;
            align-items: center;
            user-select: none;
            justify-content: center;
            text-decoration: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            background-color: $white;
            color: $lightGrey;
            border-radius: 4px;
            border: 1px solid $lighterGrey;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-transform: uppercase;
            padding: 0 !important;
            display: block;
            text-align: center;
            z-index: 9;
            line-height: 52px;
            font-size: 18px;
            font-weight: 600;
            box-shadow: none;
          }
        }
      }
    }
  }
}
@keyframes surgeAnimation {
  0% {
    top: 0;
  }
  25% {
    top: 1px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 1px;
  }
  to {
    top: 0;
  }
}
@-webkit-keyframes surgeAnimation {
  0% {
    top: 0;
  }
  25% {
    top: 1px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 1px;
  }
  to {
    top: 0;
  }
}
