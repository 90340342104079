@import "../../../../../../styles/themes/colors";
.dropdown-container {
  position: relative;
  cursor: pointer;
  color: $white;
  & > div {
    align-self: flex-end;
  }

  .dropdown {
    border: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    padding: 0;
    margin: 0;
  }
  &:after {
    content: "";
    position: absolute;
    border-top: 0;
    width: 0;
    height: 0;
    z-index: 5;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  &.account-arrow:after {
    right: 33px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  &.currency-arrow:after {
    top: 25px;
    right: 27px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
  }
  &.more-info-arrow:after {
    top: 25px;
    right: 28px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
  }
}

.menu-list {
  border-bottom: 1px solid #d5d5d5;
  color: #222;
  display: block;
  padding: 12px 15px;
  background-color: white;
  font-size: 14px;
  &:hover {
    background: #f2f2f2;
  }
}

.menu-container {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 100%;
  z-index: 5;
  background: white;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: auto;
}
.info-toolbar-dropdown-placeholder {
  display: inline-flex;
  line-height: 1;
  & > span {
    color: $white;
    & > i {
      font-size: 15px;
    }
  }
}
