@import "../../../../../styles/themes/colors";

.info-toolbar {
  // background: rgb(248, 248, 248);
  background: $lightOlive;
  .dropdown-container {
    padding: 0;
    min-width: 0;
  }
}
.container {
  line-height: 1.6;
}
.base-container {
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
  position: relative;
  padding: 0 35px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.info-links {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  &.phone-number {
    cursor: default;
    font-weight: 500;
  }
  &.info-sub-links {
    font-weight: 500;
  }
  &.info-sub-links:hover {
    color: #f2f2f2;
  }
  & > span:nth-child(1) {
    margin: 0 9px 0 6px;
  }
  & > span:nth-child(3) {
    margin: 0 8px 0 6px;
  }
  & i {
    font-size: 16px;
  }
}

.divider {
  line-height: 1;
  color: #cccccc;
  margin: 0 5px;
}

.black {
  color: $white;
}
